import React, { useState, useEffect } from 'react';
import {
  UilFocusAdd,
  UilFileAlt,
  UilEdit,
  UilEditAlt,
  UilUsersAlt,
  UilListUl,
  UilClipboard,
  UilEllipsisV,
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import propTypes from 'prop-types';
import { NavTitle } from './Style';
import { DataService } from '../config/dataService/dataService';

function MenuItems({ toggleCollapsed }) {
  const { t, i18n } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  // const dispatch = useDispatch();

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : '';
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? `${mainPathSplit[1]}_parent_${mainPathSplit[2]}` : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const [projects, setProjects] = useState([]);
  const projectItems = [];
  const [expiredProjects, setExpiredProjects] = useState([]);
  const expiredProjectItems = [];

  useEffect(() => {
    async function fetchData() {
      // setIsLoading(true);
      const projectResponse = await DataService.get(
        `v1/admin/project`,
        {
          limit: 1000,
          order_by: 'created_at',
          order_rule: 'desc',
          is_active: 1,
        },
        { Localization: i18n.language },
      );
      setProjects(projectResponse.data.data.data);

      const expiredProjectResponse = await DataService.get(
        `v1/admin/project`,
        {
          limit: 1000,
          order_by: 'created_at',
          order_rule: 'desc',
          is_active: 0,
        },
        { Localization: i18n.language },
      );
      setExpiredProjects(expiredProjectResponse.data.data.data);
      // setIsLoading(false);
    }
    fetchData();
  }, []);

  if (projects.length)
    projects.map((value) => {
      const summaryMenu = value.default_survey
        ? [
            getItem(
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/project/${value.id}/survey-summary/${value.default_survey.id}`}
              >
                <UilClipboard />
                <span>{t('surveySummaryTop')}</span>
              </NavLink>,
              `project_${value.id}_survey-summary`,
              null,
            ),
          ]
        : [];
      const projectSurveyMenu = [
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/project/${value.id}/`}>
            <UilEditAlt />
            <span>{t('projectSetting')}</span>
          </NavLink>,
          `project_${value.id}_`,
          null,
        ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/project/${value.id}/survey`}>
            <UilListUl />
            <span>{t('surveys')}</span>
          </NavLink>,
          `project_${value.id}_survey`,
          null,
        ),
      ];

      const surveySettingMenu = value.default_survey
        ? [
            getItem(
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/project/${value.id}/survey-detail/${value.default_survey.id}/`}
              >
                <UilEdit />
                <span>{t('surveySettings')}</span>
              </NavLink>,
              `project_${value.id}_survey-detail`,
              null,
            ),
          ]
        : [];

      const otherMenu = [
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/project/${value.id}/subject`}>
            <UilUsersAlt />
            <span>{t('subjects')}</span>
          </NavLink>,
          `project_${value.id}_subject`,
          null,
        ),
      ];

      return projectItems.push(
        getItem(
          `[${value.code}] ${value.name}`,
          `project_parent_${value.id}`,
          <UilFileAlt />,
          summaryMenu.concat(projectSurveyMenu).concat(surveySettingMenu).concat(otherMenu),
        ),
      );
    });

  if (expiredProjects.length)
    expiredProjects.map((value) => {
      const summaryMenu = value.default_survey
        ? [
            getItem(
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/project/${value.id}/survey-summary/${value.default_survey.id}`}
              >
                <UilClipboard />
                <span>{t('surveySummaryTop')}</span>
              </NavLink>,
              `project_${value.id}_survey-summary`,
              null,
            ),
          ]
        : [];
      const projectSurveyMenu = [
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/project/${value.id}/`}>
            <UilEditAlt />
            <span>{t('projectSetting')}</span>
          </NavLink>,
          `project_${value.id}_`,
          null,
        ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/project/${value.id}/survey`}>
            <UilListUl />
            <span>{t('surveys')}</span>
          </NavLink>,
          `project_${value.id}_survey`,
          null,
        ),
      ];

      const surveySettingMenu = value.default_survey
        ? [
            getItem(
              <NavLink
                onClick={toggleCollapsed}
                to={`${path}/project/${value.id}/survey-detail/${value.default_survey.id}/`}
              >
                <UilEdit />
                <span>{t('surveySettings')}</span>
              </NavLink>,
              `project_${value.id}_survey-detail`,
              null,
            ),
          ]
        : [];

      const otherMenu = [
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/project/${value.id}/subject`}>
            <UilUsersAlt />
            <span>{t('subjects')}</span>
          </NavLink>,
          `project_${value.id}_subject`,
          null,
        ),
      ];

      return expiredProjectItems.push(
        getItem(
          `[${value.code}] ${value.name}`,
          `project_parent_${value.id}`,
          <UilFileAlt />,
          summaryMenu.concat(projectSurveyMenu).concat(surveySettingMenu).concat(otherMenu),
        ),
      );
    });

  let items = [
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('projectManagement')}</NavTitle>,
      'active-projects',
      null,
      null,
      'group',
    ),
    // getItem(t('project'), 'project', !topMenu && <UilFileAlt />, projectItems),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/project/create`}>
        {t('newProject')}
      </NavLink>,
      'newProject',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/project/create`}>
          <UilFocusAdd />
        </NavLink>
      ),
    ),
  ];

  items = items.concat(projectItems);

  if (expiredProjectItems.length) {
    items = items.concat([
      getItem(
        !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('expiredProject')}</NavTitle>,
        'expired-projects',
        null,
        null,
        'group',
      ),
      ...expiredProjectItems,
    ]);
  }

  return items.length > 0 ? (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [`${mainPathSplit.length === 1 ? 'home' : `${mainPathSplit[1]}_${mainPathSplit[2]}_${mainPathSplit[3]}`}`]
          : []
      }
      defaultOpenKeys={
        !topMenu ? [`${mainPathSplit.length > 2 ? `${mainPathSplit[1]}_parent_${mainPathSplit[2]}` : 'dashboard'}`] : []
      }
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  ) : (
    <></>
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
